import React from "react";

import DefaultLayout from "../layout/index";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <DefaultLayout>
    <SEO title="404: Not found" />
    <div className="wrapper">
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </DefaultLayout>
);

export default NotFoundPage;
